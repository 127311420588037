/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */

import React from 'react'
import 'react-bootstrap-typeahead/css/Typeahead.css'
import { QueryClient, QueryClientProvider } from 'react-query'
import { ReactQueryDevtools } from 'react-query/devtools'
import { GlobalProvider } from './src/components/GlobalContext'
import './src/scss/theme.scss'

/** N.B. COPY CONTENTS TO GATSBY-SSR.js */

export const wrapRootElement = ({ element }) => {
  const queryClient = new QueryClient({
    defaultOptions: {
      queries: {
        // refetchOnMount: false,
        // refetchOnReconnect: false,
        refetchOnWindowFocus: false
      }
    }
  })

  return (<>
    <QueryClientProvider client={queryClient}>
      {element}
      <ReactQueryDevtools initialIsOpen={false} />
    </QueryClientProvider>
  </>)
}

export const wrapPageElement = ({ element, props }) => {
  return <GlobalProvider {...props}>{element}</GlobalProvider>
}
