
const isUndef = o => typeof o !== 'undefined'

/**
 * Use "duck" typing to map sku lock fields to Yes/<blank>.
 * @param {*} payload
 */
export const mapSkus = (payload) => {
  // use "duck" typing to determine where the skus are...
  // sim_lock
  // id_lock
  // region_lock
  if (typeof payload.sim_lock === 'boolean') payload.sim_lock = (payload.sim_lock === true ? 'Yes' : '')
  if (typeof payload.id_lock === 'boolean') payload.id_lock = (payload.id_lock === true ? 'Yes' : '')
  if (typeof payload.region_lock === 'boolean') payload.region_lock = (payload.region_lock === true ? 'Yes' : '')

  // check for listing lots...
  if (Array.isArray(payload.listing_lots)) {
    payload.listing_lots.forEach(lot => mapSkus(lot))
  } else if (Array.isArray(payload.listing_lot_skus)) {
    payload.listing_lot_skus.forEach(sku => mapSkus(sku))
  } else if (payload.data) {
    mapSkus(payload.data)
  }
  return payload
}
