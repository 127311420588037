import PropTypes from 'prop-types'
import React, { useState } from 'react'
import { useQueryClient } from 'react-query'
import { useGetuserDetailsQuery } from '../utils/api'
import { useIsAuthenticatedHook } from '../utils/firebase'
import { unauthNavTo } from './navigation'
import Spinner from './spinner'

const defaultState = { user: undefined }

export const GlobalContext = React.createContext(defaultState)

export const GlobalProvider = ({ children = null, path = '/', ...props }) => {
  // state
  const [state, setState] = useState(() => {
    return {
      setUser: (user) => setState((ps) => ({ ...ps, user })),
      ...defaultState
    }
  })

  // eslint-disable-next-line no-unused-vars
  const [user, loading, error] = useIsAuthenticatedHook()
  const getUser = useGetuserDetailsQuery()
  const queryClient = useQueryClient()
  // console.log('Get user in GC', getUser)
  const {
    setUser
  } = state
  // watch if isAuthenticated changes...
  React.useEffect(() => {
    console.log({ error, loading, user, getUser })
    console.log('getUser.data.requireOnboarding', getUser.data)
    if (path.startsWith('/app') && (error || (!loading && !user))) {
      // -> login
      console.debug('GlobalContext: User is NOT authenticated -> redirecting to login page', { path, user, loading, error })
      unauthNavTo.loginPage()
      queryClient.clear()
    } else if (path.startsWith('/app') && user && getUser.isSuccess && getUser.data.requireOnboarding === true) {
      // route the user to the onboarding path. when user has not completed the onboarding
      const { participant = null, token = null } = getUser.data
      // need to pass the invitation code and participant
      const onboardState = {}
      if (participant) {
        onboardState.participant = participant
      }
      if (token) {
        onboardState.token = token
      }
      // land user on page 3
      onboardState.targetStep = 3
      onboardState.resumeOnboarindg = true
      console.log('onboardState GC', onboardState)
      unauthNavTo.registerAccountWithToken(onboardState)
    } else if ((path === '/' || path.startsWith('/login')) && user && getUser.isSuccess) {
      // -> dashboard
      console.debug('GlobalContext: User is already logged -> redirecting to dashboard page', { path, user, loading, error })
      unauthNavTo.dashboardPage()
    }
  }, [path, user, loading, error, getUser])

  React.useEffect(() => setUser({ user }), [setUser, user])

  return <GlobalContext.Provider value={state}>
    {loading ? <div className="d-flex justify-content-center align-items-center flex-grow-1 p-5"><Spinner /></div> : children}
  </GlobalContext.Provider>
}
GlobalProvider.propTypes = {
  children: PropTypes.any
}

export default GlobalContext
