import axios from 'axios'
import { useMutation, useQuery, useQueryClient } from 'react-query'
import { getApiUrl } from '../components/api/Environment'
// temporory imigrates from old api
import { KEYS } from '../components/api/keys'
import {
  doErrorCheck,
  doSuccessCheck,
  getFormData
} from '../components/api/utils'
import { auth, useIsAuthenticatedHook } from '../utils/firebase'
//  Some global stuff that use within this file

const useMocks = process.env.GATSBY_USE_MOCKS === 'true'
if (useMocks) {
  console.debug('api: using mocks')
}
const TIMEOUT_1_MIN = 60 * 1000
const HOUR_ONE = 1000 * 60 * 60 // one hour

export const isBrowser = typeof window !== 'undefined'

export const KEY_AUTHENTICATED = () => ['authenticated']
export const KEY_USER = () => ['user']

const ARTIFICIAL_DELAY = (data) =>
  new Promise((resolve) => setTimeout(() => resolve(data), 10))

export const getFirebaseAuthToken = async () => {
  let token = null
  if (auth().currentUser) {
    token = await auth().currentUser.getIdToken()
  }
  return { authorization: `Bearer ${token}` }
}

const forceRefreshFirebaseAuthToken = async (callBackFn) => {
  const token = await auth().currentUser.getIdTokenResult(true)
  console.log('force refresh token', token)
  callBackFn(token)
}

/* axois http client */

const axiosConfig = {
  headers: {
    'Content-Type': 'application/json'
  },
  timeout: 30000,
  // baseURL: (process.env.GATSBY_API_BASEURL || 'http://localhost:8889')
  baseURL: process.env.GATSBY_API_BASEURL || getApiUrl()
}

export const client = axios.create(axiosConfig)

/* endpoints */

// ************************************

// check if user is authenticated with Firebase
// this doesnt mean that the user is authenticated to the server
export const isAuthenticated = () => {
  // if token is null, then we're not authenticated!
  const user = auth().currentUser
  // console.debug('isAuthenticated', token)
  if (user === null) {
    console.debug('firebase user is null')
    return false
  }
  // otherwise, logged in...
  return true
}

/**
 * Performs a login of the given credentials.
 */
export const useLoginMutation = () => {
  const queryClient = useQueryClient()
  return useMutation(async ({ username = '', password = '' }) => {
    console.debug(`logging in as '${username}'`)
    if (username.trim().length === 0 || password.trim().length === 0) {
      throw new Error('Credentials cannot be blank.')
    }
    return new Promise((resolve, reject) => {
      auth().signInWithEmailAndPassword(username, password).then(async () => {
        const authHeader = await getFirebaseAuthToken()
        client.get('/api/v1/users', { headers: { ...authHeader } }).then(() => {
          resolve()
        }).catch((err) => {
          console.log('error from loggin in', err)
          auth().signOut()
          reject(err)
        })
      }).catch((err) => {
        reject(err)
      })
    })
  })
}

/**
 * Checks if we have a JWT token. Assumption is that it's valid.
 * @returns
 */
export const useIsAuthenticated = (enabled = false) => {
  return useQuery(
    KEY_AUTHENTICATED(),
    async () => {
      return isAuthenticated()
    },
    {
      enabled,
      cacheTime: 0,
      staleTime: 0,
      refetchInterval: 10000,
      retry: 0
    }
  )
}

/**
 * Performs a logout of the current user.
 */
export const useLogoutMutation = () => {
  const queryClient = useQueryClient()
  return useMutation(
    async () => {
      await auth().signOut() // this would change the user state to null in GC
    },
    {
      onSuccess: () => {
        queryClient.clear()
        // queryClient.resetQueries(KEY_USER(), { exact: true })
        // clear() should trigger before the signOut()
      }
    }
  )
}

/**
 * Forgot Password (unauth)
 */
export const useDoForgotPasswordMutation = () => {
  return useMutation(async ({ emailAddress }) => {
    return client
      .post('/api/v1/forgetpassword', { email: emailAddress })
      .then((res) => {
        return res
      })
  })
}

/**
 * Reset Password (unauth) using token
 */
export const useDoResetPasswordMutation = () => {
  return useMutation(async ({ token, password }) => {
    return client
      .post('/api/v1/resetpassword', { token: token, password: password })
      .then((res) => {
        return res
      })
  })
}

/**
 * Gets a User's details.
 * @returns
 */
export const useGetuserDetailsQuery = () => {
  const [user, loading, error] = useIsAuthenticatedHook()
  return useQuery(
    KEY_USER(),
    async () => {
      if (useMocks) {
        await ARTIFICIAL_DELAY()
        return require('../components/fixtures/api.v2.users.details')
      } else {
        const authHeader = await getFirebaseAuthToken()
        return client.get('/api/v1/users', { headers: { ...authHeader } }).then((res) => {
          /** @type {UserDetails} */
          const user = res.data
          const {
            id,
            first_name: firstName,
            last_name: lastName,
            email,
            role_type
          } = user
          if (isBrowser) {
            const userId = `${id}`
            const displayName = `${firstName} ${lastName}`
            window.localStorage.setItem('userId', userId)
            window.localStorage.setItem('displayName', displayName)
            window.localStorage.setItem('email', email)
            if (window.Cohere) {
              console.debug(`Signing into Cohere as email='${email}'`)
              window.Cohere.identify(userId, {
                displayName,
                email
              })
            } else {
              console.debug('Cohere not present.')
            }
          }
          res.data._isVendor = role_type === 'seller' || role_type === 'both'
          return res.data
        })
      }
    },
    {
      // cacheTime: HOUR_ONE,
      // staleTime: HOUR_ONE
      retry: 1,
      enabled: user !== null && (!loading) && (!error),
      onError: () => {
        auth().signOut()
      }
    }
  )
}

/**
 * Update user personal detail
 * @returns res
 */
export const useUpdatePersonDetail = () => {
  const queryClient = useQueryClient()
  return useMutation(
    async (payload) => {
      if (useMocks) {
        await ARTIFICIAL_DELAY()
        return {}
        // return require('./fixtures/api.v2.users.details')
      } else {
        console.log('update personal detail')
        const authHeader = await getFirebaseAuthToken()
        return client
          .patch('/api/v1/users', payload, { headers: { ...authHeader } })
          .then((res) => {
            return res
          })
      }
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(KEY_USER())
        queryClient.invalidateQueries('my_account')
      }
    }
  )
}

/**
 *
 * Update user password while user is logged in.
 * @returns
 */
export const useUpdateUserPassword = () => {
  return useMutation(async (payload) => {
    if (useMocks) {
      await ARTIFICIAL_DELAY()
      return {}
      // return require('./fixtures/api.v2.users.details')
    } else {
      console.log('update user password')
      const authHeader = await getFirebaseAuthToken()
      return client
        .post('/api/v1/users/resetpassword', payload, {
          headers: { ...authHeader }
        })
        .then((res) => {
          return res
        })
    }
  })
}

/**
 * use switch user to super role
 * @returns res
 */
export const useSetSwitchToSuperRole = (callBackFn) => {
  return useMutation(
    async () => {
      if (useMocks) {
        await ARTIFICIAL_DELAY()
        return {}
        // return require('./fixtures/api.v2.users.details')
      } else {
        console.log('switch user to super role')
        const authHeader = await getFirebaseAuthToken()
        return client
          .post('/api/v1/super/switch', null, { headers: { ...authHeader } })
          .then((res) => {
            return res
          })
      }
    },
    {
      onSuccess: async () => {
        forceRefreshFirebaseAuthToken(callBackFn)
      }
    }
  )
}

/**
 * use switch user back to admin
 * @returns res
 */
export const useSetReverToAdminRole = (callBackFn) => {
  return useMutation(
    async () => {
      if (useMocks) {
        await ARTIFICIAL_DELAY()
        return {}
        // return require('./fixtures/api.v2.users.details')
      } else {
        console.log('switch user back to default role')
        const authHeader = await getFirebaseAuthToken()
        return client
          .post('/api/v1/super/reset', null, { headers: { ...authHeader } })
          .then((res) => {
            return res
          })
      }
    },
    {
      onSuccess: async () => {
        forceRefreshFirebaseAuthToken(callBackFn)
      }
    }
  )
}

/**
 * Impersonate a company via being a super user
 * @returns res
 */
export const useSetImpersonateCompany = (callBackFn) => {
  return useMutation(
    async (payload) => {
      if (useMocks) {
        await ARTIFICIAL_DELAY()
        return {}
        // return require('./fixtures/api.v2.users.details')
      } else {
        console.log('impersonate a company', payload)
        const authHeader = await getFirebaseAuthToken()
        return client
          .post('/api/v1/super/impersonate', payload, {
            headers: { ...authHeader }
          })
          .then((res) => {
            return res
          })
      }
    },
    {
      onSuccess: async () => {
        forceRefreshFirebaseAuthToken(callBackFn)
      },
      onError: () => {
        console.log('Unable to impersonate the person due to permission')
      }
    }
  )
}

/**
 * Get Companies list
 */

export const useGetCompaniesQuery = () => {
  // const queryClient = useQueryClient()
  const getUser = useGetuserDetailsQuery()
  return useQuery(
    KEYS.VENDOR.COMPANIES(),
    async () => {
      const comapnyNewId = getUser.data.companyId
      const authHeader = await getFirebaseAuthToken()
      console.log('companyId:' + comapnyNewId)
      return client
        .get('/api/v1/company/' + comapnyNewId + '/participants', {
          headers: { ...authHeader }
        })
        .then(doSuccessCheck)
        .catch(doErrorCheck)
    },
    {
      cacheTime: TIMEOUT_1_MIN,
      staleTime: TIMEOUT_1_MIN,
      enabled: getUser.isSuccess
    }
  )
}
export const useGetGroupsQuery = () => {
  const getUser = useGetuserDetailsQuery()
  return useQuery(
    KEYS.VENDOR.GROUPS(),
    async () => {
      const comapnyNewId = getUser.data.companyId
      const authHeader = await getFirebaseAuthToken()
      console.log('companyId:' + comapnyNewId)
      return client
        .get('/api/v1/company/' + comapnyNewId + '/groups', {
          headers: { ...authHeader }
        })
        .then(doSuccessCheck)
        .catch(doErrorCheck)
    },
    {
      cacheTime: TIMEOUT_1_MIN,
      staleTime: TIMEOUT_1_MIN,
      enabled: getUser.isSuccess
    }
  )
}
export const useUpdateGroup = () => {
  const queryClient = useQueryClient()
  const getUser = useGetuserDetailsQuery()
  return useMutation(
    async ({ group }) => {
      if (!getUser.isSuccess) {
        // throw error to the front end
        throw new Error('Company Id not valid')
      }
      const comapnyNewId = getUser.data.companyId
      const authHeader = await getFirebaseAuthToken()
      return client
        .put(
          '/api/v1/company/' + comapnyNewId + '/groups/' + group.id,
          group,
          { headers: { ...authHeader } }
        )
        .then((res) => {
          return res
        })
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(KEYS.VENDOR.COMPANIES())
        queryClient.invalidateQueries(KEYS.VENDOR.GROUPS())
      },
      onError: (err) => {
        if (err.response.status === 400) {
          err.message = 'Unable to create group. Group name already exists.'
        }
      }
    }
  )
}
export const useDeleteGroups = () => {
  const queryClient = useQueryClient()
  const getUser = useGetuserDetailsQuery()
  return useMutation(
    async ({ groupIds }) => {
      if (!getUser.isSuccess) {
        // throw error to the front end
        throw new Error('Company Id not valid')
      }
      console.log('groupIds', groupIds)
      const comapnyNewId = getUser.data.companyId
      const authHeader = await getFirebaseAuthToken()
      return client
        .post('/api/v1/company/' + comapnyNewId + '/groups/delete', { groupIds: groupIds }, {
          headers: { ...authHeader }
        })
        .then((res) => {
          return res
        })
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(KEYS.VENDOR.COMPANIES())
        queryClient.invalidateQueries(KEYS.VENDOR.GROUPS())
      }
    }
  )
}
export const useCreateGroup = () => {
  const queryClient = useQueryClient()
  const getUser = useGetuserDetailsQuery()
  return useMutation(
    async ({ group }) => {
      if (!getUser.isSuccess) {
        // throw error to the front end
        throw new Error('Company Id not valid')
      }
      const comapnyNewId = getUser.data.companyId
      const authHeader = await getFirebaseAuthToken()
      return client
        .post(
          '/api/v1/company/' + comapnyNewId + '/groups/create',
          group,
          { headers: { ...authHeader } }
        )
        .then((res) => {
          return res
        })
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(KEYS.VENDOR.COMPANIES())
        queryClient.invalidateQueries(KEYS.VENDOR.GROUPS())
      },
      onError: (err) => {
        if (err.response.status === 400) {
          err.message = 'Unable to create group. Group name already exists.'
        }
      }

    }
  )
}
export const useInviteCompany = () => {
  const queryClient = useQueryClient()
  const getUser = useGetuserDetailsQuery()
  return useMutation(
    async ({ invitedCompany }) => {
      console.log(invitedCompany)
      if (!getUser.isSuccess) {
        // throw error to the front end
        throw new Error('Company Id not valid')
      }
      const comapnyNewId = getUser.data.companyId
      const authHeader = await getFirebaseAuthToken()
      return client
        .post(
          '/api/v1/company/' + comapnyNewId + '/participants/invite',
          invitedCompany,
          { headers: { ...authHeader } }
        )
        .then((res) => {
          return res
        })
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(KEYS.VENDOR.COMPANIES())
      }
    }
  )
}
const HEADER_CONTENTTYPE_FORMDATA = { 'Content-Type': 'multipart/form-data' }
const HEADER_CONTENTTYPE_APPLICATION = { 'Content-Type': 'application/json' }
export const useUploadCompanyProfile = () => {
  const queryClient = useQueryClient()
  return useMutation(async ({ companyProfile, token }) => {
    console.log(companyProfile)
    const authHeader = await getFirebaseAuthToken()
    let url = '/api/v1/company'
    if (token) {
      url = `${url}?token=${token}`
    }
    return client
      .post(url, getFormData(companyProfile), {
        headers: { ...HEADER_CONTENTTYPE_FORMDATA, ...authHeader }
      })
      .then((res) => {
        return res
      })
  }, {
    onSuccess: () => {
      queryClient.clear()
      queryClient.invalidateQueries('my_account')
      queryClient.invalidateQueries(KEY_USER())
    }
  })
}

// sign up new company

export const useSignUpWithToken = () => {
  return useMutation(
    async ({ credentials, token, member, participant }) => {
      console.log(credentials)
      let url = '/api/v1/unauth/user'
      if (token) {
        url = `${url}?token=${token}`
        if (member && member !== 'null') {
          url = url.concat('&member=' + member)
        }
        if (participant && participant !== 'null') {
          url = url.concat('&participant=' + participant)
        }
      }
      const authHeader = await getFirebaseAuthToken()
      return client
        .post(url, credentials, { headers: { ...authHeader } })
        .then((res) => {
          return res
        })
      // .catch((res) => { console.log({ res }) })
    },
    {
      onSuccess: () => {
        // queryClient.invalidateQueries(KEYS.VENDOR.COMPANIES())
      }
    }
  )
}

// check if the token is valid or expired.
export const useCheckTokenIsValid = () => {
  return useMutation(async ({ token }) => {
    const authHeader = await getFirebaseAuthToken()
    return client
      .get('/api/v1/invite?token=' + token, { headers: { ...authHeader } })
      .then((res) => {
        return res
      })
    // .catch((res) => { console.log({ res }) })
  })
}

// get participant invite comapny name

export const useCheckInviteCompanyName = () => {
  return useMutation(async ({ token }) => {
    const authHeader = await getFirebaseAuthToken()
    return client
      .get('/api/v1/participants/invite/' + token, {
        headers: { ...authHeader }
      })
      .then((res) => {
        return res
      })
  })
}

// existing use accept invitation after login
export const useSetAcceptParticipantInvite = () => {
  return useMutation(async ({ token }) => {
    const authHeader = await getFirebaseAuthToken()
    return client
      .patch(
        '/api/v1/company/participants/invite/accept?token=' + token,
        {payload: null},
        {
          headers: { ...authHeader }
        }
      )
      .then((res) => {
        return res
      })
  })
}

// ANCHOR  delete participants account

export const useDeleteAccountMutation = () => {
  const queryClient = useQueryClient()
  const getUser = useGetuserDetailsQuery()
  return useMutation(
    async ({ participants = [] }) => {
      // const authHeader = getFakeToken()
      if (!getUser.isSuccess) {
        // throw error to the front end
        throw new Error('Company Id not valid')
      }
      const comapnyNewId = getUser.data.companyId
      const authHeader = await getFirebaseAuthToken()
      // console.log(authHeader)
      // console.log(participants)
      if (participants.length > 0) {
        return client({
          method: 'delete',
          url: '/api/v1/company/' + comapnyNewId + '/participants',
          data: participants,
          headers: { ...authHeader }
        })
        // .delete(
        //   '/api/v1/company/' + SUPER_TOKEN + '/participants',
        //   participants,
        //   { headers: { ...authHeader } }
        // )
        // .then(doSuccessCheck)
        // .catch(doErrorCheck)
      }
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(KEYS.VENDOR.COMPANIES())
        queryClient.refetchQueries(KEYS.VENDOR.COMPANIES())
      }
    }
  )
}

// get company informations in viewing page

export const useViewParticipantCompanyQuery = (listingId) => {
  const getUser = useGetuserDetailsQuery()
  return useQuery(
    [...KEYS.VENDOR.PARTICIPANT_INFO(), listingId],
    async () => {
      const authHeader = await getFirebaseAuthToken()
      if (!getUser.isSuccess) {
        // throw error to the front end
        throw new Error('Company Id not valid')
      }
      const comapnyNewId = getUser.data.companyId
      console.log(listingId)
      return client
        .get(
          '/api/v1/company/' +
            comapnyNewId +
            '/participants/' +
            listingId.listingId,
          {
            headers: { ...authHeader }
          }
        )
        .then((res) => {
          return res
        })
        .then(doSuccessCheck)
        .catch(doErrorCheck)
    },
    { enabled: getUser.isSuccess }
  )
}

// update nickname
export const useUpdateParticipantCompanyDetail = () => {
  const queryClient = useQueryClient()
  const getUser = useGetuserDetailsQuery()
  return useMutation(
    async (idAndNicknameArray) => {
      if (!getUser.isSuccess) {
        // throw error to the front end
        throw new Error('Company Id not valid')
      }
      const comapnyNewId = getUser.data.companyId
      const authHeader = await getFirebaseAuthToken()
      return client
        .patch(
          '/api/v1/company/' + comapnyNewId + '/participants',
          idAndNicknameArray,
          {
            headers: { ...authHeader }
          }
        )
        .then((res) => {
          return res
        })
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(KEYS.VENDOR.PARTICIPANT_INFO())
        queryClient.invalidateQueries(KEYS.VENDOR.COMPANIES())
        queryClient.refetchQueries(KEYS.VENDOR.COMPANIES())
      }
    }
  )
}

// update company status
export const useUpdateParticipantCompanyStatus = () => {
  const queryClient = useQueryClient()
  const getUser = useGetuserDetailsQuery()
  return useMutation(
    async (status) => {
      const authHeader = await getFirebaseAuthToken()
      if (!getUser.isSuccess) {
        // throw error to the front end
        throw new Error('Company Id not valid')
      }
      const comapnyNewId = getUser.data.companyId
      console.log(status)
      return client
        .patch(
          '/api/v1/company/' +
            comapnyNewId +
            '/participants/' +
            status.listingId +
            '/status',
          status.body,
          {
            headers: { ...authHeader }
          }
        )
        .then((res) => {
          return res
        })
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(KEYS.VENDOR.PARTICIPANT_INFO())
        queryClient.invalidateQueries(KEYS.VENDOR.COMPANIES())
        queryClient.invalidateQueries()
        // manuly refetch for participants page
        queryClient.refetchQueries(KEYS.VENDOR.COMPANIES())
      }
    }
  )
}

// Get company info by Id
// Under My Account
export const useGetCompanyInfoById = () => {
  const queryClient = useQueryClient()
  const getUser = useGetuserDetailsQuery()
  return useQuery(
    'my_account',
    async () => {
      const authHeader = await getFirebaseAuthToken()
      if (!queryClient.getQueryData(KEY_USER())) {
        // throw error to the front end
        throw new Error('Company Id not valid')
      }
      const comapnyNewId = getUser.data.companyId
      return client
        .get('/api/v1/company/' + comapnyNewId, { headers: { ...authHeader } })
        .then((res) => {
          return res.data
        })
      // .catch((res) => { console.log({ res }) })
    },
    { enabled: getUser.isSuccess }
  )
}

// Update company detail
// Under My Accounts
export const useUpdateCompanyDetail = () => {
  const queryClient = useQueryClient()
  const getUser = useGetuserDetailsQuery()
  return useMutation(
    async (payload) => {
      const authHeader = await getFirebaseAuthToken()
      if (!getUser.isSuccess) {
        // throw error to the front end
        throw new Error('Company Id not valid')
      }
      const comapnyNewId = getUser.data.companyId
      console.log(payload)
      return client
        .put('/api/v1/company/' + comapnyNewId, getFormData(payload.payload), {
          headers: { ...HEADER_CONTENTTYPE_FORMDATA, ...authHeader }
        })
        .then((res) => {
          return res
        })
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries('my_account')
        queryClient.invalidateQueries(KEYS.VENDOR.PARTICIPANT_INFO())
      }
    }
  )
}

// invite member to company

export const useInviteMemberToCompany = () => {
  const queryClient = useQueryClient()
  const getUser = useGetuserDetailsQuery()
  return useMutation(async ({ invitedMember }) => {
    console.log(invitedMember)
    if (!getUser.isSuccess) {
      // throw error to the front end
      throw new Error('Company Id not valid')
    }
    const comapnyNewId = getUser.data.companyId
    const authHeader = await getFirebaseAuthToken()
    return client
      .post(
        '/api/v1/company/' + comapnyNewId + '/members/invite',
        invitedMember,
        { headers: { ...authHeader } }
      )
      .then((res) => {
        return res
      })
  },
  {
    onSuccess: () => {
      queryClient.invalidateQueries('my_account')
    }
  })
}

/**
 * Update members details
 *
 */
export const useUpdateMemberDetail = () => {
  const queryClient = useQueryClient()
  const getUser = useGetuserDetailsQuery()
  return useMutation(
    async (payload) => {
      if (useMocks) {
        await ARTIFICIAL_DELAY()
        return {}
        // return require('./fixtures/api.v2.users.details')
      } else {
        if (!getUser.isSuccess) {
          // throw error to the front end
          throw new Error('Company Id not valid')
        }
        console.log('update member detail')
        console.log(payload)
        const authHeader = await getFirebaseAuthToken()
        const comapnyNewId = getUser.data.companyId
        return client
          .patch(
            '/api/v1/company/' + comapnyNewId + '/members/' + payload.memberId,
            payload.memberDetails,
            { headers: { ...authHeader } }
          )
          .then((res) => {
            return res
          })
      }
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries('my_account')
      }
    }
  )
}

// ANCHOR  delet member in a company

export const useDeleteMember = () => {
  const queryClient = useQueryClient()
  const getUser = useGetuserDetailsQuery()
  return useMutation(
    async (participants) => {
      // const authHeader = getFakeToken()
      if (!getUser.isSuccess) {
        // throw error to the front end
        throw new Error('Company Id not valid')
      }
      const comapnyNewId = getUser.data.companyId
      const authHeader = await getFirebaseAuthToken()
      return client({
        method: 'delete',
        url: '/api/v1/company/' + comapnyNewId + '/members',
        data: participants,
        headers: { ...authHeader }
      })
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries('my_account')
        queryClient.invalidateQueries(KEY_USER())
      }
    }
  )
}

/**
 * Get Branding information
 */
// TODO move to BrandingClientApi
export const useGetCompanyBrandingQuery = () => {
  // const queryClient = useQueryClient()
  const getUser = useGetuserDetailsQuery()
  return useQuery(
    KEYS.VENDOR.COMPANIES_BRANDING(),
    async () => {
      const authHeader = await getFirebaseAuthToken()

      return client
        .get('/api/v2/branding/me', {
          headers: { ...authHeader }
        })
        .then(doSuccessCheck)
        .catch(doErrorCheck)
    },
    {
      retry: 0,
      cacheTime: TIMEOUT_1_MIN,
      staleTime: TIMEOUT_1_MIN,
      enabled: getUser.isSuccess
    }
  )
}

// Update Branding information

// TODO move to BrandingClientApi
export const useUpdateCompanyBranding = () => {
  const queryClient = useQueryClient()
  const getUser = useGetuserDetailsQuery()
  return useMutation(
    async (newData) => {
      const authHeader = await getFirebaseAuthToken()
      if (!getUser.isSuccess) {
        // throw error to the front end
        throw new Error('Company Id not valid')
      }
      console.log(newData)
      return client
        .post('/api/v2/branding/me', getFormData(newData), {
          headers: { ...HEADER_CONTENTTYPE_FORMDATA, ...authHeader }
        })
    },
    {
      onSuccess: () => {
        // update branding data in edit page...
        queryClient.invalidateQueries(KEYS.VENDOR.COMPANIES_BRANDING())
        // update overall branding of current site...
        queryClient.invalidateQueries(KEYS.BRANDING())
      }
    }
  )
}

//
// get email notification configurations
//
// TODO move to EmailNotificationsClientApi
export const useGetEmailNotificaitonConfigQuery = () => {
  // const queryClient = useQueryClient()
  const getUser = useGetuserDetailsQuery()
  return useQuery(
    KEYS.VENDOR.COMPANIES_EMAIL_CONFIG(),
    async () => {
      const authHeader = await getFirebaseAuthToken()

      return client
        .get('/api/v1/tenants/settings/emailNotification', {
          headers: { ...authHeader }
        })
        .then(doSuccessCheck)
        .catch(doErrorCheck)
    },
    {
      retry: 0,
      cacheTime: TIMEOUT_1_MIN,
      staleTime: TIMEOUT_1_MIN,
      enabled: getUser.isSuccess
    }
  )
}

// update
// TODO move to EmailNotificationsClientApi
export const useUpdateEmailConfigurations = () => {
  const queryClient = useQueryClient()
  const getUser = useGetuserDetailsQuery()
  return useMutation(
    async (payload) => {
      const authHeader = await getFirebaseAuthToken()
      if (!getUser.isSuccess) {
        // throw error to the front end
        throw new Error('Company Id not valid')
      }
      return client
        .put('/api/v1/tenants/settings/emailNotification', payload, {
          headers: { ...authHeader }
        })
        .then((res) => {
          return res
        })
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(KEYS.VENDOR.COMPANIES_EMAIL_CONFIG())
      }
    }
  )
}

// Update the status of accept terms and conditions

export const useUpdateTermsAndConditions = () => {
  const queryClient = useQueryClient()
  const getUser = useGetuserDetailsQuery()
  let listintId = ''
  return useMutation(
    async (payload) => {
      const authHeader = await getFirebaseAuthToken()
      listintId = payload.listingId
      console.log(authHeader)
      if (!getUser.isSuccess) {
        // throw error to the front end
        throw new Error('Company Id not valid')
      }
      return client
        .put('/api/buyer/v2/listings/' + payload.listingId + '/acceptTermsAndConditions', payload, {
          headers: { ...authHeader }
        })
        .then((res) => {
          return res
        })
    },
    {
      onSuccess: (e) => {
        console.log(listintId)
        queryClient.invalidateQueries(KEYS.BUYER.LISTING(listintId))
      }
    }
  )
}

// Communications page in buyers view

// post a new question

export const useSubmitNewQuestion = () => {
  const queryClient = useQueryClient()
  const getUser = useGetuserDetailsQuery()
  let currentListintId = ''
  return useMutation(
    async (newData) => {
      const authHeader = await getFirebaseAuthToken()
      if (!getUser.isSuccess) {
        // throw error to the front end
        throw new Error('Company Id not valid')
      }

      console.log(newData)
      const { listingId, newQuestion } = newData
      currentListintId = listingId
      return client
        .post('/api/buyer/v2/listings/' + listingId + '/askQuestion', newQuestion, {
          headers: { ...HEADER_CONTENTTYPE_APPLICATION, ...authHeader }
        })
    },
    {
      onSuccess: () => {
        // update overall branding of current site...
        queryClient.invalidateQueries(KEYS.BUYER.LISTING(currentListintId))
      }
    }
  )
}

// delete a new question

export const useDeleteQuestion = () => {
  const queryClient = useQueryClient()
  const getUser = useGetuserDetailsQuery()
  let currentListintId = ''
  return useMutation(
    async (payload) => {
      const authHeader = await getFirebaseAuthToken()
      if (!getUser.isSuccess) {
        // throw error to the front end
        throw new Error('Company Id not valid')
      }

      console.log(payload)
      const { listingId, questionID } = payload
      currentListintId = listingId
      return client
        .delete('/api/buyer/v2/listings/' + listingId + '/questions/' + questionID, {
          headers: { ...authHeader }
        })
    },
    {
      onSuccess: () => {
        // update overall branding of current site...
        queryClient.invalidateQueries(KEYS.BUYER.LISTING(currentListintId))
      }
    }
  )
}

//  Publish answer to a question

export const usePublishAnswerToQuestion = () => {
  const queryClient = useQueryClient()
  const getUser = useGetuserDetailsQuery()
  let listintId = ''
  return useMutation(
    async (payload) => {
      const authHeader = await getFirebaseAuthToken()
      listintId = payload.listingId
      console.log(authHeader)
      if (!getUser.isSuccess) {
        // throw error to the front end
        throw new Error('Company Id not valid')
      }
      return client
        .put('/api/seller/v2/listings/' + payload.listingId + '/questions/' + payload.questionId, payload.newAnswer, {
          headers: { ...authHeader }
        })
        .then((res) => {
          return res
        })
    },
    {
      onSuccess: (e) => {
        console.log(listintId)
        queryClient.invalidateQueries(KEYS.VENDOR.LISTING(listintId))
      }
    }
  )
}

// delete a new question from seller

export const useSellerDeleteQuestion = () => {
  const queryClient = useQueryClient()
  const getUser = useGetuserDetailsQuery()
  let currentListintId = ''
  return useMutation(
    async (payload) => {
      const authHeader = await getFirebaseAuthToken()
      if (!getUser.isSuccess) {
        // throw error to the front end
        throw new Error('Company Id not valid')
      }

      console.log(payload)
      const { listingId, questionID } = payload
      currentListintId = listingId
      return client
        .delete('/api/seller/v2/listings/' + listingId + '/questions/' + questionID, {
          headers: { ...authHeader }
        })
    },
    {
      onSuccess: () => {
        // update overall branding of current site...
        queryClient.invalidateQueries(KEYS.VENDOR.LISTING(currentListintId))
      }
    }
  )
}
