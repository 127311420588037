import { mapSkus } from './mapperSku'

/**
 * Converts a JSON object to Form Data.
 * @param {*} object
 * @returns
 */
export const getFormData = (object) => {
  return Object.entries(object).reduce((formData, [key, value]) => {
    console.debug('appending formdata', { key, value, type: typeof value })
    if (key === 'address' || Array.isArray(value)) {
      formData.append(key, JSON.stringify(value))
    } else {
      formData.append(key, value)
    }

    return formData
  }, new FormData())
}

/**
 * Converts a JSON object to Form Data.
 * @param {*} object
 * @returns
 */
export const getFormDataV2 = (object) => {
  return Object.entries(object).reduce((formData, [key, value]) => {
    console.debug('appending formdata', { key, value, type: typeof value })
    if (typeof value === 'object' || Array.isArray(value)) {
      formData.append(key, JSON.stringify(value))
    } else {
      formData.append(key, value)
    }

    return formData
  }, new FormData())
}

export const HEADER_CONTENTTYPE_FORMDATA = { 'Content-Type': 'multipart/form-data' }

/**
 * Check for 'success=false' in the payload (despite 200 OK response).
 */
export const doSuccessCheck = (res, context = 'No context provided') => {
  let { success, errors, message } = res.data
  if (typeof success === 'undefined') success = res.data['success=']
  if (typeof errors === 'undefined') errors = res.data['errors=']
  if (typeof message === 'undefined') message = res.data['message=']
  // we get 200... but there's sometimes still errors...
  if (typeof success !== 'undefined' && success === false) {
    if (typeof errors !== 'undefined' && Array.isArray(errors)) {
      const msg = errors.map(({ field_name: field, message }) => `${field} - ${message}.`).join(' ')
      console.error(msg, res.data)
      throw new Error(msg)
    } else if (typeof message === 'string') {
      throw new Error(message)
    } else {
      const msg = 'An error occurred - ' + context
      console.error(msg, res.data)
      throw new Error('An error occurred - ' + context)
    }
  } else {
    // map data...
    mapSkus(res.data)
    return res.data
  }
}

/**
   * Check for underlying error reason in the response payload.
   */
export const doErrorCheck = (err) => {
  console.error('Error creating listing: ' + err.message, { stack: err.message })
  if (err.response && err.response.data && err.response.data.response) throw new Error(err.response.data.response)
  else throw err
}
